var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[(!_vm.mounted)?_c('v-row',_vm._l(([0, 1, 2]),function(card){return _c('v-col',{key:card.title,attrs:{"cols":"12","lg":"4"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"boilerplate":false,"type":"card","tile":""}})],1)}),1):_c('v-row',{key:_vm.cardsKeys},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('assignCard',{attrs:{"context":{
          ID_Structure: _vm.structureID,
          title: 'Adresse de correspondance',
          key: 'ID_AdresseCorrespondance',
          type: 'correspondance',
        },"disabled":!_vm.hasCodeFederal,"adresse":_vm.adresseCorespondance(),"structureAdresses":_vm.structureAdresses},on:{"onCardUpdate":_vm.setData}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('assignCard',{attrs:{"context":{
          ID_Structure: _vm.structureID,
          title: 'Adresse légale',
          key: 'ID_AdresseSiegeSocial',
          type: 'legal',
        },"disabled":!_vm.hasCodeFederal,"adresse":_vm.adresseSiegeSocial(),"structureAdresses":_vm.structureAdresses},on:{"onCardUpdate":_vm.setData}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('assignCard',{attrs:{"context":{
          ID_Structure: _vm.structureID,
          title: 'Adresse d\'accueil',
          key: 'ID_AdresseAccueil',
          type: 'accueil',
        },"disabled":!_vm.hasCodeFederal,"adresse":_vm.adresseAccueil(),"structureAdresses":_vm.structureAdresses},on:{"onCardUpdate":_vm.setData}})],1)],1),_c('v-row',{staticClass:"mt-5",attrs:{"no-gutters":""}},[(!_vm.mounted)?_c('v-skeleton-loader',{staticStyle:{"width":"100%"},attrs:{"boilerplate":false,"type":"table-tbody","tile":""}}):_c('div',{staticClass:"w-100"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"subtitle-1"},[_vm._v("Liste de mes adresses")]),_c('v-spacer'),(_vm.hasCodeFederal)?_c('v-btn',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.openAddModal(0)}}},[_vm._v("Ajouter")]):_vm._e(),_c('AdresseModal',{ref:"adresseModal",attrs:{"isOpen":_vm.modalAddAddressIsOpen,"context":{ ID_Structure: _vm.currentStructureId },"tabInit":_vm.tabInit},on:{"onClose":function($event){_vm.modalAddAddressIsOpen = false},"onSelected":_vm.onItemSelected,"onUpdated":_vm.onUpdated}})],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Nom adresse")]),_c('th',{staticClass:"text-left"},[_vm._v("Adresse")]),_c('th',{staticClass:"text-left"},[_vm._v("Code postal")]),_c('th',{staticClass:"text-left"},[_vm._v("Ville")]),_c('th',{staticClass:"text-left"},[_vm._v("Action")])])]),_c('tbody',_vm._l((_vm.structureAdresses),function(item,index){return _c('AdresseRow',{key:index,attrs:{"context":{
                ID_Structure: _vm.currentStructureId,
                adresse: item,
                hasCodeFederal: _vm.hasCodeFederal,
              }}})}),1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }