<template>
  <div class="w-100">
    <v-row v-if="!mounted">
      <v-col v-for="card in [0, 1, 2]" :key="card.title" cols="12" lg="4">
        <v-skeleton-loader
          :boilerplate="false"
          type="card"
          tile
          class="mx-auto"
        />
      </v-col>
    </v-row>
    <v-row :key="cardsKeys" v-else>
      <v-col cols="12" lg="4">
        <assignCard
          :context="{
            ID_Structure: structureID,
            title: 'Adresse de correspondance',
            key: 'ID_AdresseCorrespondance',
            type: 'correspondance',
          }"
          :disabled="!hasCodeFederal"
          :adresse="adresseCorespondance()"
          :structureAdresses="structureAdresses"
          @onCardUpdate="setData"
        ></assignCard>
      </v-col>
      <v-col cols="12" lg="4">
        <assignCard
          :context="{
            ID_Structure: structureID,
            title: 'Adresse légale',
            key: 'ID_AdresseSiegeSocial',
            type: 'legal',
          }"
          :disabled="!hasCodeFederal"
          :adresse="adresseSiegeSocial()"
          :structureAdresses="structureAdresses"
          @onCardUpdate="setData"
        ></assignCard>
      </v-col>
      <v-col cols="12" lg="4">
        <assignCard
          :context="{
            ID_Structure: structureID,
            title: 'Adresse d\'accueil',
            key: 'ID_AdresseAccueil',
            type: 'accueil',
          }"
          :disabled="!hasCodeFederal"
          :adresse="adresseAccueil()"
          :structureAdresses="structureAdresses"
          @onCardUpdate="setData"
        ></assignCard>
      </v-col>
    </v-row>

    <v-row class="mt-5" no-gutters>
      <v-skeleton-loader
        v-if="!mounted"
        :boilerplate="false"
        type="table-tbody"
        tile
        style="width: 100%"
      />

      <div v-else class="w-100">
        <v-toolbar flat>
          <v-toolbar-title class="subtitle-1"
            >Liste de mes adresses</v-toolbar-title
          >

          <v-spacer></v-spacer>
          <v-btn
            v-if="hasCodeFederal"
            class="btn btn-primary"
            @click="openAddModal(0)"
            >Ajouter</v-btn
          >
          <AdresseModal
            :isOpen="modalAddAddressIsOpen"
            :context="{ ID_Structure: currentStructureId }"
            @onClose="modalAddAddressIsOpen = false"
            @onSelected="onItemSelected"
            :tabInit="tabInit"
            @onUpdated="onUpdated"
            ref="adresseModal"
          ></AdresseModal>
        </v-toolbar>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Nom adresse</th>
                <th class="text-left">Adresse</th>
                <th class="text-left">Code postal</th>
                <th class="text-left">Ville</th>
                <th class="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              <AdresseRow
                v-for="(item, index) in structureAdresses"
                :key="index"
                :context="{
                  ID_Structure: currentStructureId,
                  adresse: item,
                  hasCodeFederal,
                }"
              />
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import {sleep} from "@/plugins/utils"
import AdresseModal from "@/components/utils/modals/adresses.vue";
import AdresseRow from "@/components/Adresse/list/tableRow.vue";
import assignCard from "@/components/Adresse/Adresse__SelectAssignCard.vue";
// import {success_notification, error_notification} from "@/plugins/notifications"
import { bus } from "@/main";

export default {
  name: "Domiciliation",

  components: {
    AdresseModal,
    AdresseRow,
    assignCard,
  },

  data: () => ({
    mounted: false,
    modalAddAddressIsOpen: false,
    dialogDeleteConfirm: false,
    structureAdresses: null,
    cards: [],
    cardsKeys: 0,
    tabInit: 0,
    structureID: null,
    structure: {},
  }),

  async created() {
    bus.$on("onStructureAdresseUpdated", async () => {
      return await this.setData();
    });
    await this.setData();
    this.mounted = true;
  },

  computed: {
    ...mapGetters("structure", [
      "currentStructure",
      "currentStructureId",
      "hasCodeFederal",
    ]),
  },

  methods: {
    ...mapActions("structure", [
      "getStructureAdresses",
      "deleteStructureAdresse",
      "getStructureByID",
    ]),
    openAddModal(item) {
      this.tabInit = item;
      this.modalAddAddressIsOpen = true;
    },
    onItemSelected(item) {
      // console.log("pârent", item);
    },

    async setData() {
      this.structureID = parseInt(this.$route.params.id);

      if (!this.structureID) {
        this.structureID = this.currentStructureId;
      }

      this.structure = await this.getStructureByID(this.structureID);

      this.structureAdresses = [];
      this.structureAdresses = await this.getStructureAdresses({
        ID_Structure: this.structureID,
      });

      this.refreshCards();
      return;
    },

    adresseSiegeSocial() {
      let ID_AdresseSiegeSocial = this.currentStructure.STR_Structure
        .ID_AdresseSiegeSocial
        ? this.currentStructure.STR_Structure.ID_AdresseSiegeSocial
        : this.structure.ID_AdresseSiegeSocial;
      let adresse = this.structureAdresses.find(
        (adr) => adr.id == ID_AdresseSiegeSocial
      );
      return adresse;
    },

    adresseCorespondance() {
      let ID_AdresseCorrespondance = this.currentStructure.STR_Structure
        .ID_AdresseCorrespondance
        ? this.currentStructure.STR_Structure.ID_AdresseCorrespondance
        : this.structure.ID_AdresseCorrespondance;
      let adresse = this.structureAdresses.find(
        (adr) => adr.id == ID_AdresseCorrespondance
      );
      return adresse;
    },

    adresseAccueil() {
      let ID_AdresseAccueil = this.currentStructure.STR_Structure
        .ID_AdresseAccueil
        ? this.currentStructure.STR_Structure.ID_AdresseAccueil
        : this.structure.ID_AdresseAccueil;
      let adresse = this.structureAdresses.find(
        (adr) => adr.id == ID_AdresseAccueil
      );
      return adresse;
    },

    refreshCards() {
      // console.log(this.currentStructure.STR_Structure.ID_AdresseAccueil);

      this.cardsKeys += 1;
    },

    onUpdated() {
      this.modalAddAddressIsOpen = false;
    },
  },
};
</script>
