<template>
  <tr>
    <td>{{ context.adresse.NomAdresse }}</td>
    <td>{{ context.adresse.Adresse1 }}</td>
    <td>{{ context.adresse.CodePostal }}</td>
    <td>{{ context.adresse.Ville }}</td>
    <td>
      <v-btn v-if="context.hasCodeFederal" icon color="blue-primary">
        <v-icon @click="openEditDialog">mdi-pencil</v-icon>
      </v-btn>

      <v-btn v-if="canBeDeleted && context.hasCodeFederal" icon color="error">
        <v-icon @click="dialogDeleteConfirm = true">mdi-delete</v-icon>
      </v-btn>
    </td>

    <v-dialog v-model="dialogDeleteConfirm" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Supprimer l'adresse</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn btn-red"
            :loading="loading"
            @click="dialogDeleteConfirm = false"
            >Annuler</v-btn
          >
          <v-btn
            class="btn btn-primary"
            :loading="loading"
            @click="deleteAdresse"
            >Valider</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <AdresseModal
      v-if="modalEditIsOpen"
      :isOpen="modalEditIsOpen"
      :item="context.adresse"
      :context="context"
      @onClose="modalEditIsOpen = false"
      @onSelected="onSelected"
      @onAdded="onAdded"
      @onEdited="onEdited"
      :tabInit="tabInit"
    ></AdresseModal>
  </tr>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AdresseModal from "@/components/utils/modals/adresses.vue";
import {
  success_notification,
  error_notification,
} from "@/plugins/notifications";

export default {
  components: { AdresseModal },

  data: () => ({
    dialogDeleteConfirm: false,
    modalEditIsOpen: false,
    tabInit: null,
    formattedAdresse: "",
  }),

  props: {
    context: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },

  created() {},

  computed: {
    ...mapGetters("structure", ["currentStructure", "currentStructureId"]),

    canBeDeleted() {
      if (
        this.currentStructure.STR_Structure.ID_AdresseAccueil ===
          this.context.adresse.id ||
        this.currentStructure.STR_Structure.ID_AdresseCorrespondance ===
          this.context.adresse.id ||
        this.currentStructure.STR_Structure.ID_AdresseSiegeSocial ===
          this.context.adresse.id
      ) {
        return false;
      }
      return true;
    },
  },

  methods: {
    ...mapActions("structure", ["deleteStructureAdresse"]),

    async deleteAdresse() {
      try {
        await this.deleteStructureAdresse(this.context);
        success_notification("Adresse suprimée");
        this.$emit("onDeleted", this.context.adresse);
        this.adresses = [];
        this.dialogDeleteConfirm = false;
      } catch (e) {
        error_notification("Oups, une erreur est survenue...");
      }
    },

    openEditDialog() {
      this.tabInit = 1;
      this.modalEditIsOpen = true;
    },

    onSelected(item) {
      this.$emit("onSelected", item);
      this.modalEditIsOpen = false;
    },

    onAdded(item) {
      this.$emit("onAdded", item);
      this.modalEditIsOpen = false;
    },
    onEdited(item) {
      this.$emit("onEdited", item);
      this.modalEditIsOpen = false;
    },
  },
};
</script>
